import React, {Component} from "react";
import Nav from "./NavBar";
import {MDBCol, MDBIcon, MDBInput, MDBRow, MDBTable, MDBTableBody} from "mdbreact";
import Footer from "./footer";
import {useParams} from "react-router";
import {connect} from "react-redux";
import {ReCAPTCHA} from "react-google-recaptcha";
import {toast, ToastContainer} from "react-toastify";
import {BrowserView, MobileView} from "react-device-detect";
import {withTranslation} from "react-i18next";
import {LanguageContext} from "../LanguageContext";


class ContactUs extends Component{
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            name: null,
            email: null,
            msg: null,
            mobile: null,
            lang: this.props.params.lang,
        }
    }

    chooseLang(){

        if(this.state.lang === 'fa'){
            localStorage.setItem("lang", this.state.lang);
        }else {
            if(this.state.lang === 'en'){
                localStorage.setItem("lang", this.state.lang);
            }else {
                if(localStorage.getItem("lang") === null) {
                    localStorage.setItem("lang", 'fa');
                }
            }
        }

        var l = localStorage.getItem("lang")
        this.languageChangeListener = () =>{
            var lang = localStorage.getItem("lang")

        }

        this.props.i18n.on('languageChanged', this.languageChangeListener);

        if (l !== null) {
            const {setLanguage} = this.context;
            setLanguage(l)

            this.props.i18n.changeLanguage(l);
        }
    }

    componentDidMount() {

        this.chooseLang()

        if(localStorage.getItem("profile") == null) {
            this.getUserProfile()
        }

    }

    getUserProfile(){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            },
        };
        fetch('https://uharmonymatch.com/api/v1/profile/', requestOptions)

            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {
                localStorage.setItem('profile', JSON.stringify({
                    is_registration_complete: response.data.is_registration_complete,
                    is_seen_videos: response.data.is_seen_videos,
                    is_verified: response.data.is_verified
                }));
                this.props.user_profile({
                    is_registration_complete: response.data.is_registration_complete,
                    is_verified: response.data.is_verified
                })
                this.setState();

            })
            .catch((error) => {
                if (error === 401) {
                    this.setState()
                } else {
                    if(error == 403) {
                        this.setState({is_block: true});
                    }else {
                        if (error == 429) {

                        }
                    }
                }

            });
    }

    desktopView(){

        if(this.props.i18n.language === 'fa'){
            return (
                <body className="text-right bg-body" dir="rtl">
                <ToastContainer/>
                <Nav/>

                <MDBRow className={'m-3 p-3'}>
                    <MDBCol></MDBCol>
                    <MDBCol>
                        <div className={'m-3 mt-5'}>
                            <img  src='../images/hertgreen.png' className={'img-fluid'}/>
                        </div>
                    </MDBCol>
                    <MDBCol>
                        <MDBTable borderless responsive style={{marginTop: '30px'}}>
                            <MDBTableBody >
                                <tr>
                                    <td align={'right'} style={{verticalAlign: 'right'}} >
                                        {this.props.i18n.t('address')}
                                    </td>
                                    <td align={'left'} style={{verticalAlign: 'left'}} >
                                        {this.props.i18n.t('address_footer_title')}
                                    </td>
                                </tr>
                                <tr>
                                    <td  align={'right'} style={{verticalAlign: 'right', width: '1px', whiteSpace: 'nowrap'}} >
                                        {this.props.i18n.t('phone_contact_us')}
                                    </td>
                                    <td align={'left'} style={{verticalAlign: 'left'}} >
                                        <a href="https://chat.whatsapp.com/I4cEKmZeNLPAnnbk2YH856" style={{all: 'unset'}}><label className={'mb-2'} style={{direction: 'ltr'}}> +447593999124</label></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td align={'right'} style={{verticalAlign: 'right'}} >
                                        {this.props.i18n.t('email_contact_us')}
                                    </td>
                                    <td align={'left'} style={{verticalAlign: 'left'}} >
                                        admin@uharmonymatch.com
                                    </td>
                                </tr>
                                <tr>
                                    <td  align={'right'} style={{verticalAlign: 'right', width: '1px', whiteSpace: 'nowrap'}} >
                                        {this.props.i18n.t('socials_footer_title')}:
                                    </td>
                                    <td align={'right'} style={{verticalAlign: 'right'}} >
                                        <div className={'text-left'}>
                                            <a target="_blank" rel="noopener noreferrer" href='https://chat.whatsapp.com/I4cEKmZeNLPAnnbk2YH856' className='me-4 text-reset'>
                                                <MDBIcon fab icon="whatsapp" />
                                            </a>

                                            <a target="_blank" rel="noopener noreferrer" href='https://www.youtube.com/channel/UCIHPJ_lCxTHhy5zpGuiGbWg' className='me-4 text-reset'>
                                                <MDBIcon fab icon="youtube" />
                                            </a>
                                            <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/invites/contact/?i=1cccgzxuiqj00&utm_content=g27sif5' className='me-4 text-reset'>
                                                <MDBIcon fab icon="instagram" />
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </MDBTableBody>
                        </MDBTable>

                    </MDBCol>
                    <MDBCol></MDBCol>
                </MDBRow>

                <MDBRow>
                    <div style={{background: '#EEEEEE'}} className={'text-center p-4'}>
                    <span style={{fontWeight: 'bold'}} >
                        {this.props.i18n.t('contact_us_form_footer')}
                    </span>
                    </div>
                </MDBRow>

                <MDBRow>
                    <div className={'text-center'}>
                        <label className={'mt-4'}>{this.props.i18n.t("contact_us_form_title")}</label>
                        <center><hr style={{width: '50%'}}/></center>

                        <div>
                            <label className={'text-right'} style={{ fontSize: '13px', paddingLeft: '430px'}}>{this.props.i18n.t('contact_us_form_name')}<span style={{color: 'red'}}>*</span></label>

                            <MDBInput
                                // value={formValue.fname}
                                name='fname'
                                style={{width: '40%', margin: 'auto'}}
                                onChange={this.getNameOnChange.bind(this)}
                                id='validationCustom01'
                                required
                            />
                        </div>

                        <label className={'text-right'} style={{paddingLeft: '450px', fontSize: '13px'}}>{this.props.i18n.t('contact_us_form_email')}<span style={{color: 'red'}}>*</span></label>

                        <MDBInput
                            // value={formValue.fname}
                            name='fname'
                            style={{width: '40%', margin: 'auto'}}
                            onChange={this.getEmailOnChange.bind(this)}
                            id='validationCustom01'
                            required
                            type={'email'}
                        />

                        <div>
                            <label className={'text-right'} style={{ fontSize: '13px', paddingLeft: '430px'}}>{this.props.i18n.t('contact_us_form_mobile')}<span style={{color: 'red'}}>*</span></label>

                            <MDBInput
                                // value={formValue.fname}
                                name='fname'
                                style={{width: '40%', margin: 'auto'}}
                                onChange={this.getMobileOnChange.bind(this)}
                                type="number"
                                id='validationCustom01'
                                required
                            />
                        </div>


                        <label className={'text-right'} style={{paddingLeft: '460px', fontSize: '13px'}}>{this.props.i18n.t('contact_us_form_subject')}<span style={{color: 'red'}}>*</span></label>

                        <MDBInput
                            // value={formValue.fname}
                            name='fname'
                            style={{width: '40%', margin: 'auto', height: '100px'}}
                            onChange={this.getMSGOnChange.bind(this)}
                            id='validationCustom01'
                            type={'textarea'}
                            required
                        />
                        <ReCAPTCHA
                            sitekey="6LcocD4oAAAAAAnpmzspkpOkdxFwqjKVHuhGV3c1" // Replace with your Site Key
                            onChange={this.handleCaptchaChange}
                        />
                        <div className={'text-left mb-3'} style={{paddingLeft: '430px'}}>
                            <button className={'btn btn-success'} onClick={(e)=>this.submit(e)}>{this.props.i18n.t('contact_us_form_send_btn')}</button>
                        </div>

                    </div>
                </MDBRow>

                <Footer
                    isEnglish={this.props.i18n.language === 'en'}
                />

                </body>
            );
        }else {
            return (
                <body className="text-right bg-body" dir="rtl">
                <ToastContainer/>
                <Nav/>

                <MDBRow className={'m-3 p-3 ltr'}>
                    <MDBCol></MDBCol>
                    <MDBCol>
                        <div className={'m-3 mt-5'}>
                            <img  src='../images/hertgreen.png' className={'img-fluid'}/>
                        </div>
                    </MDBCol>
                    <MDBCol>
                        <MDBTable borderless responsive style={{marginTop: '35px'}}>
                            <MDBTableBody >
                                <tr>
                                    <td align={'left'} style={{verticalAlign: 'left'}} >
                                        {this.props.i18n.t('address')}
                                    </td>
                                    <td align={'left'} style={{verticalAlign: 'left'}} >
                                        {this.props.i18n.t('address_footer_title')}
                                    </td>
                                </tr>
                                <tr>
                                    <td  align={'left'} style={{verticalAlign: 'left'}} >
                                        {this.props.i18n.t('phone_contact_us')}
                                    </td>
                                    <td align={'left'} style={{verticalAlign: 'left'}} >
                                        <a href="https://chat.whatsapp.com/I4cEKmZeNLPAnnbk2YH856" style={{all: 'unset'}}><label className={'mb-2'} style={{direction: 'ltr'}}> +447593999124</label></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td align={'left'} style={{verticalAlign: 'right'}} >
                                        {this.props.i18n.t('email_contact_us')}
                                    </td>
                                    <td align={'left'} style={{verticalAlign: 'left'}} >
                                        admin@uharmonymatch.com
                                    </td>
                                </tr>
                                <tr>
                                    <td  align={'left'} style={{verticalAlign: 'right', width: '1px', whiteSpace: 'nowrap'}} >
                                        {this.props.i18n.t('socials_footer_title')}:
                                    </td>
                                    <td align={'right'} style={{verticalAlign: 'right'}} >
                                        <div className={'text-left'}>
                                            <a target="_blank" rel="noopener noreferrer" href='https://chat.whatsapp.com/I4cEKmZeNLPAnnbk2YH856' className='me-4 text-reset'>
                                                <MDBIcon fab icon="whatsapp" />
                                            </a>

                                            <a target="_blank" rel="noopener noreferrer" href='https://www.youtube.com/channel/UCIHPJ_lCxTHhy5zpGuiGbWg' className='me-4 text-reset'>
                                                <MDBIcon fab icon="youtube" />
                                            </a>
                                            <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/invites/contact/?i=1cccgzxuiqj00&utm_content=g27sif5' className='me-4 text-reset'>
                                                <MDBIcon fab icon="instagram" />
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </MDBTableBody>
                        </MDBTable>

                    </MDBCol>
                    <MDBCol></MDBCol>
                </MDBRow>

                <MDBRow className={'ltr'}>
                    <div style={{background: '#EEEEEE'}} className={'text-center p-4'}>
                    <span style={{fontWeight: 'bold'}} >
                        {this.props.i18n.t('contact_us_form_footer')}
                    </span>
                    </div>
                </MDBRow>

                <MDBRow className={'ltr'}>
                    <div className={'text-center'}>
                        <label className={'mt-4'}>{this.props.i18n.t("contact_us_form_title")}</label>
                        <center><hr style={{width: '50%'}}/></center>
                        <div className={'text-left'} style={{marginLeft: '435px', fontSize: '13px'}}>
                            <label className={'text-left'}>{this.props.i18n.t('contact_us_form_name')}<span style={{color: 'red'}}>*</span></label>
                        </div>
                        <MDBInput
                            // value={formValue.fname}
                            name='fname'
                            style={{width: '40%', margin: 'auto'}}
                            onChange={this.getNameOnChange.bind(this)}
                            id='validationCustom01'
                            required
                        />

                        <div className={'text-left'} style={{marginLeft: '435px', fontSize: '13px'}}>
                            <label className={'text-left'}>{this.props.i18n.t('contact_us_form_email')}<span style={{color: 'red'}}>*</span></label>
                        </div>
                        <MDBInput
                            // value={formValue.fname}
                            name='fname'
                            style={{width: '40%', margin: 'auto'}}
                            onChange={this.getEmailOnChange.bind(this)}
                            id='validationCustom01'
                            required
                            type={'email'}
                        />

                        <div className={'text-left'} style={{marginLeft: '435px', fontSize: '13px'}}>
                            <label className={'text-left'}>{this.props.i18n.t('contact_us_form_mobile')}<span style={{color: 'red'}}>*</span></label>
                        </div>
                        <MDBInput
                            // value={formValue.fname}
                            name='fname'
                            style={{width: '40%', margin: 'auto'}}
                            onChange={this.getMobileOnChange.bind(this)}
                            id='validationCustom01'
                            type="number"
                            required
                        />


                        <div className={'text-left'} style={{marginLeft: '435px', fontSize: '13px'}}>
                            <label className={'text-left'}>{this.props.i18n.t('contact_us_form_subject')}<span style={{color: 'red'}}>*</span></label>
                        </div>
                        <MDBInput
                            // value={formValue.fname}
                            name='fname'
                            style={{width: '40%', margin: 'auto'}}
                            onChange={this.getMSGOnChange.bind(this)}
                            id='validationCustom01'
                            type={'textarea'}
                            required
                        />
                        <ReCAPTCHA
                            sitekey="6LcocD4oAAAAAAnpmzspkpOkdxFwqjKVHuhGV3c1" // Replace with your Site Key
                            onChange={this.handleCaptchaChange}
                        />
                        <div className={'text-right mb-3'} style={{marginRight: '430px'}}>
                            <button className={'btn btn-success'} onClick={(e)=>this.submit(e)}>{this.props.i18n.t('contact_us_form_send_btn')}</button>
                        </div>

                    </div>
                </MDBRow>

                <Footer
                    isEnglish={this.props.i18n.language === 'en'}
                />

                </body>
            );
        }
    }

    mobileView(){
        if(this.props.i18n.language === 'fa'){
            return(
                <body className="text-right bg-body" dir="rtl">
                <div className={'container-fluid'}>
                    <ToastContainer/>
                    <Nav/>

                    <MDBRow className={'m-2 p-2 text-center'}>

                        <div className={'text-center'}>
                            <img  src='../images/hertgreen.png' className={'img-fluid text-center'}/>
                        </div>
                    </MDBRow>

                    <MDBRow className={'m-2 p-2'}>

                        <MDBCol>
                            {this.props.i18n.t('address')}
                        </MDBCol>
                        <MDBCol>
                            {this.props.i18n.t('address_footer_title')}
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className={'m-2 p-2'}>
                        <MDBCol>
                            {this.props.i18n.t('phone_contact_us')}
                        </MDBCol>
                        <MDBCol>
                            <a href="https://chat.whatsapp.com/I4cEKmZeNLPAnnbk2YH856" style={{all: 'unset'}}><label className={'mb-2'} style={{direction: 'ltr'}}> +447593999124  </label></a>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className={'m-2 p-2'}>
                        <MDBCol>
                            {this.props.i18n.t('email_contact_us')}
                        </MDBCol>
                        <MDBCol>
                            <a href="mailto: admin@uharmonymatch.com" style={{all: 'unset'}}><label className={'mb-2'}> admin@uharmonymatch.com</label></a>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className={'m-2 p-2'}>
                        <MDBCol>
                            {this.props.i18n.t('socials_footer_title')}
                        </MDBCol>
                        <MDBCol>
                            <div className={'text-left'}>
                                <a target="_blank" rel="noopener noreferrer" href='https://chat.whatsapp.com/I4cEKmZeNLPAnnbk2YH856' className='me-4 text-reset'>
                                    <MDBIcon fab icon="whatsapp" />
                                </a>

                                <a target="_blank" rel="noopener noreferrer" href='https://www.youtube.com/channel/UCIHPJ_lCxTHhy5zpGuiGbWg' className='me-4 text-reset'>
                                    <MDBIcon fab icon="youtube" />
                                </a>
                                <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/invites/contact/?i=1cccgzxuiqj00&utm_content=g27sif5' className='me-4 text-reset'>
                                    <MDBIcon fab icon="instagram" />
                                </a>
                            </div>
                        </MDBCol>

                    </MDBRow>
                    <MDBRow>
                        <div style={{background: '#EEEEEE'}} className={'text-center p-4'}>
                    <span style={{fontWeight: 'bold'}} >
                        {this.props.i18n.t('contact_us_form_footer')}
                    </span>
                        </div>
                    </MDBRow>

                    <MDBRow>
                        <div className={'text-center'}>
                            <label className={'mt-4'}>{this.props.i18n.t('contact_us_form_title')}</label>
                            <center><hr style={{width: '90%'}}/></center>
                            <div className={'text-center'} style={{ fontSize: '13px'}}>
                                <label className={'text-right'}>{this.props.i18n.t('contact_us_form_name')}<span style={{color: 'red'}}>*</span></label>
                            </div>
                            <MDBInput
                                // value={formValue.fname}
                                name='fname'
                                style={{width: '60%', margin: 'auto'}}
                                onChange={this.getNameOnChange.bind(this)}
                                id='validationCustom01'
                                required
                            />

                            <div className={'text-center'} style={{ fontSize: '13px'}}>
                                <label className={'text-right'}>{this.props.i18n.t('contact_us_form_email')}<span style={{color: 'red'}}>*</span></label>
                            </div>
                            <MDBInput
                                // value={formValue.fname}
                                name='fname'
                                style={{width: '60%', margin: 'auto'}}
                                onChange={this.getEmailOnChange.bind(this)}
                                id='validationCustom01'
                                required
                                type={'email'}
                            />



                            <div className={'text-center'} style={{ fontSize: '13px'}}>
                                <label className={'text-right'}>{this.props.i18n.t('contact_us_form_mobile')}<span style={{color: 'red'}}>*</span></label>
                            </div>
                            <MDBInput
                                // value={formValue.fname}
                                name='fname'
                                style={{width: '60%', margin: 'auto'}}
                                onChange={this.getMobileOnChange.bind(this)}
                                id='validationCustom01'
                                required
                                type={'email'}
                            />


                            <div className={'text-center'} style={{ fontSize: '13px'}}>
                                <label className={'text-right'}>{this.props.i18n.t('contact_us_form_subject')}<span style={{color: 'red'}}>*</span></label>
                            </div>
                            <MDBInput
                                // value={formValue.fname}
                                name='fname'
                                style={{width: '60%', margin: 'auto'}}
                                onChange={this.getMSGOnChange.bind(this)}
                                id='validationCustom01'
                                type={'textarea'}
                                required
                            />
                            <ReCAPTCHA
                                sitekey="6LcocD4oAAAAAAnpmzspkpOkdxFwqjKVHuhGV3c1" // Replace with your Site Key
                                onChange={this.handleCaptchaChange}
                            />
                            <div className={'text-left mb-3'} style={{marginLeft: '65px'}}>
                                <button className={'btn btn-success'} onClick={(e)=>this.submit(e)}>{this.props.i18n.t('contact_us_form_send_btn')}</button>
                            </div>

                        </div>
                    </MDBRow>

                    <Footer
                        isEnglish={this.props.i18n.language === 'en'}
                    />
                </div>
                </body>
            )
        }
        return(
            <body className="text-right bg-body ltr" dir="ltr">
            <div className={'container-fluid'}>
                <ToastContainer/>
                <Nav/>

                <MDBRow className={'m-2 p-2 text-center'}>

                    <div className={'text-center'}>
                        <img  src='../images/hertgreen.png' className={'img-fluid text-center'}/>
                    </div>
                </MDBRow>

                <MDBRow className={'m-2 p-2 text-left'}>

                    <MDBCol>
                        {this.props.i18n.t('address')}
                    </MDBCol>
                    <MDBCol className={'text-end align-items-end align-content-end'}>
                        {this.props.i18n.t('address_footer_title')}
                    </MDBCol>
                </MDBRow>
                <MDBRow className={'m-2 p-2 text-left'}>
                    <MDBCol>
                        {this.props.i18n.t('phone_contact_us')}
                    </MDBCol>
                    <MDBCol className={'text-end align-items-end align-content-end'}>
                        <a href="https://chat.whatsapp.com/I4cEKmZeNLPAnnbk2YH856" style={{all: 'unset'}}><label className={'mb-2'} style={{direction: 'ltr'}}> +447593999124  </label></a>
                    </MDBCol>
                </MDBRow>
                <MDBRow className={'m-2 p-2 text-left'}>
                    <MDBCol>
                        {this.props.i18n.t('email_contact_us')}
                    </MDBCol>
                    <MDBCol className={'text-end align-items-end align-content-end'}>
                        <a href="mailto: admin@uharmonymatch.com" style={{all: 'unset'}}><label className={'mb-2'}> admin@uharmonymatch.com</label></a>
                    </MDBCol>
                </MDBRow>
                <MDBRow className={'m-2 p-2 text-left'}>
                    <MDBCol>
                        {this.props.i18n.t('socials_footer_title')}
                    </MDBCol>
                    <MDBCol className={'text-end align-items-end align-content-end'}>
                        <div className={'text-end'}>
                            <a target="_blank" rel="noopener noreferrer" href='https://chat.whatsapp.com/I4cEKmZeNLPAnnbk2YH856' className='me-4 text-reset'>
                                <MDBIcon fab icon="whatsapp" />
                            </a>

                            <a target="_blank" rel="noopener noreferrer" href='https://www.youtube.com/channel/UCIHPJ_lCxTHhy5zpGuiGbWg' className='me-4 text-reset'>
                                <MDBIcon fab icon="youtube" />
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/invites/contact/?i=1cccgzxuiqj00&utm_content=g27sif5' className='me-4 text-reset'>
                                <MDBIcon fab icon="instagram" />
                            </a>
                        </div>
                    </MDBCol>

                </MDBRow>
                <MDBRow>
                    <div style={{background: '#EEEEEE'}} className={'text-center p-4'}>
                    <span style={{fontWeight: 'bold'}} >
                        {this.props.i18n.t('contact_us_form_footer')}
                    </span>
                    </div>
                </MDBRow>

                <MDBRow>
                    <div className={'text-center'}>
                        <label className={'mt-4'}>{this.props.i18n.t('contact_us_form_title')}</label>
                        <center><hr style={{width: '90%'}}/></center>
                        <div className={'text-center'} style={{ fontSize: '13px'}}>
                            <label className={'text-right'}>{this.props.i18n.t('contact_us_form_name')}<span style={{color: 'red'}}>*</span></label>
                        </div>
                        <MDBInput
                            // value={formValue.fname}
                            name='fname'
                            style={{width: '60%', margin: 'auto'}}
                            onChange={this.getNameOnChange.bind(this)}
                            id='validationCustom01'
                            required
                        />

                        <div className={'text-center'} style={{ fontSize: '13px'}}>
                            <label className={'text-right'}>{this.props.i18n.t('contact_us_form_email')}<span style={{color: 'red'}}>*</span></label>
                        </div>
                        <MDBInput
                            // value={formValue.fname}
                            name='fname'
                            style={{width: '60%', margin: 'auto'}}
                            onChange={this.getEmailOnChange.bind(this)}
                            id='validationCustom01'
                            required
                            type={'email'}
                        />

                        <div className={'text-center'} style={{ fontSize: '13px'}}>
                            <label className={'text-right'}>{this.props.i18n.t('contact_us_form_mobile')}<span style={{color: 'red'}}>*</span></label>
                        </div>
                        <MDBInput
                            // value={formValue.fname}
                            name='fname'
                            style={{width: '60%', margin: 'auto'}}
                            onChange={this.getMobileOnChange.bind(this)}
                            id='validationCustom01'
                            required
                            type="number"
                        />


                        <div className={'text-center'} style={{ fontSize: '13px'}}>
                            <label className={'text-right'}>{this.props.i18n.t('contact_us_form_subject')}<span style={{color: 'red'}}>*</span></label>
                        </div>
                        <MDBInput
                            // value={formValue.fname}
                            name='fname'
                            style={{width: '60%', margin: 'auto'}}
                            onChange={this.getMSGOnChange.bind(this)}
                            id='validationCustom01'
                            type={'textarea'}
                            required
                        />
                        <ReCAPTCHA
                            sitekey="6LcocD4oAAAAAAnpmzspkpOkdxFwqjKVHuhGV3c1" // Replace with your Site Key
                            onChange={this.handleCaptchaChange}
                        />
                        <div className={'text-left mb-3'} style={{marginLeft: '65px'}}>
                            <button className={'btn btn-success'} onClick={(e)=>this.submit(e)}>{this.props.i18n.t('contact_us_form_send_btn')}</button>
                        </div>

                    </div>
                </MDBRow>
                {console.log(this.props.i18n.language)}
                <Footer

                    isEnglish={this.props.i18n.language === 'en'}
                />
            </div>
            </body>
        )
    }
    render() {

        return (
            <div>
                <ToastContainer/>
                <BrowserView>
                    {this.desktopView()}
                </BrowserView>
                <MobileView>
                    {this.mobileView()}
                </MobileView>
            </div>
        );

    }

    handleCaptchaChange(value) {
        console.log("Captcha value:", value);
    }

    getNameOnChange(value){
        this.state.name = value.target.value
    }
    getEmailOnChange(value){
        this.state.email = value.target.value
    }

    getMobileOnChange(value){
        this.state.mobile = value.target.value
    }

    getMSGOnChange(value){
        this.state.msg = value.target.value
    }

    validateEmail(inputEmail){
        // Regular expression for basic email validation
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        return emailRegex.test(inputEmail);
    }
    submit(s){

        if(this.state.name == null || this.state.name == '' && this.state.email == null || this.state.email == '' && this.state.msg == null || this.state.msg == '' && this.state.mobile == null || this.state.mobile == '') {
            toast.error(this.props.i18n.t('contact_us_send_msg_error_toast'), {
                position: toast.POSITION.TOP_LEFT
            });
        }else {
            if(this.validateEmail(this.state.email) == false){
                toast.error(this.props.i18n.t('email_validation_toast'), {
                    position: toast.POSITION.TOP_LEFT
                });
            }else {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        name: this.state.name,
                        mobile: this.state.mobile,
                        email: this.state.email,
                        msg: this.state.msg
                    })

                };
                fetch('https://uharmonymatch.com/api/v1/contact-us/', requestOptions)

                    .then((response) => {
                        return response.json()
                    })
                    .then((response) => {
                        if (response.status === 400) {
                            toast.error(this.props.i18n.t('send_msg_error_toast'), {
                                position: toast.POSITION.TOP_LEFT
                            });
                        } else {
                            if (response.status === 201) {
                                toast.info(this.props.i18n.t('send_msg_success_toast'), {
                                    position: toast.POSITION.TOP_LEFT
                                });
                            }
                        }
                    });
            }

        }
    }

}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

function  mapState(state){
    return {
        user_profile: state.user_profile
    }
}

const updateProfile =(dispatch)=>{
    return{
        user_profile: (item)=>{ dispatch({type: 'UPDATE_PROFILE', item}) }
    }
}

export default connect(mapState, updateProfile)(withParams(withTranslation()(ContactUs)));